:root {
  --sidebar-width: 298px;
}

@mixin fixed_content {
  @apply my-4;
  @apply lg:fixed;
  top: 68px;
  @apply hlg:top-12;
  @apply hlg:mt-5;
  bottom: 48px;
}

@mixin sidebar {
  width: var(--sidebar-width);
  padding-top: 44px;
  transition: transform .3s ease-in-out;
  background: linear-gradient(180deg, var(--primary-600) 0%, var(--primary-400) 40.62%, var(--secondary-400) 70.73%, var(--secondary-700) 99.87%, var(--tertiary-200) 113.73%);
  @apply text-white;
  ul {
    li {
      @apply font-semibold;
      margin: 14px 0;

      &.parentActive {
        position: relative;

        &:before {
          content: "";
          background: #5CBEC6;
          @apply rounded-l-[10px] h-full top-0 bottom-0 left-[18px] right-0 absolute -z-10;
        }

        &:after {
          content: "";
          @apply absolute bg-primary-100 rounded-[10px] h-[40px] w-[10px] -left-[5px] top-0;
        }
      }

      a, .menu {
        margin-left: 20px;
        padding: 8px 20px;
        border-radius: 45px 0 0 45px;
        display: block;
        cursor: pointer;

        svg {
          vertical-align: text-bottom;
          margin-right: 15px;
        }
      }
      &.active {
        @apply font-bold;
        a {
          background: #5CBEC6;
        }

        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -5px;
          width: 10px;
          height: 100%;
          border-radius: 10px;
          background: var(--primary-100);
        }

        &.child {
          a {
            @apply rounded-l-[10px] bg-primary-600 pl-0 ml-10;
          }

          &:before {
            content: none;
          }
        }
      }
    }
  }
}

.sidebarExpanded {
  transform: translateX(0px);
}

.sidebarCollapse {
  transform: translateX(-254px) !important;
}

.expandIcon {
  transition: transform .3s ease-in-out;
}

.collapseIcon {
  transform: rotate(180deg);
}

.sidebarCollapseBtn {
  width: 42px;
  height: 42px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 42px;
  position: absolute;
  top: 12px;
  right: -20px;
  cursor: pointer;
  z-index: 10;
}

.sidebar {
  display: none;
  @apply lg:block;
  @include fixed_content;
  border-radius: 0 30px 30px 0;
  @include sidebar;
}

.sidebarDrawer {
  @include sidebar;
  height: 100%;
}

.main {
  @include fixed_content;
  overflow: overlay;
  overflow: auto;
  left: 0;
  right: 0;
  @apply px-2 sm:px-4;
  @apply lg:ml-[310px] visible;
  //margin-left: calc(var(--sidebar-width) + 30px);
  @apply lg:pr-[30px] visible;
  //@apply py-2.5;
  scroll-behavior: smooth;
  transition: margin .3s ease-in-out;
}

.expandMain {
  margin-left: 54px !important;
}

.popup {
  @apply mt-7;
  .controls {
    position: absolute;
    top: 23px;
    right: 10px;

    svg {
      display: block;

      &:hover {
        background: var(--grey1);
      }

      //&.up {
      //  position: absolute;
      //  top: 25px;
      //  right: 10px;
      //}
      //&.down {
      //  position: absolute;
      //  top: 36px;
      //  right: 10px;
      //}
    }
  }

  .content {
    border-radius: 10px;
    @apply mt-7;
    @apply bg-primary-50;
    @apply p-5;
    @apply text-left;
    .upload {
      @apply mt-2;
      .icon, .text {
        display: inline-block;
      }

      .icon {
        @apply bg-white;
        border-radius: 8px;
        @apply border border-primary-800 p-5 cursor-pointer;
        svg {
          @apply text-primary-800;
        }

        img {
          width: 50px;
          height: 50px;
          display: inherit;
          object-fit: contain;
        }
      }

      .text {
        vertical-align: middle;

        .logo_name {
          @apply font-regular text-body block text-left capitalize ml-2.5;
        }
      }
    }

    .closeIcon {
      position: absolute;
      right: -10px;
      top: 40%;
      cursor: pointer;

      svg {
        @apply rounded-full bg-white text-body p-1;
      }
    }
  }
}
