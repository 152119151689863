@import 'src/styles/colors.scss';
@import 'src/styles/shadows.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: overlay;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background;
  @apply text-body;
}

* {
  font-family: 'Open Sans', sans-serif !important;
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  @apply bg-white;
  @apply py-2.5;
  @apply px-1;
  @apply sm:px-12 drop-shadow sm:drop-shadow-none;
  .logo {
    min-width: 122px;
    img {
      @apply h-8 sm:h-11;
      display: inline-block;
      @apply hlg:h-8;
    }
    .logo-img {
      min-width: 122px;
    }
  }

  .menu {
    @apply lg:hidden;
  }
}

.clip-path {
  height: 525px;
  @apply hlg:h-96;
  clip-path: polygon(0 0, 100% 0%, 100% 50%, 0 100%);
  @apply w-full;
  @apply absolute;
  z-index: -1;

  &.primary {
    background: linear-gradient(
      88.04deg,
      var(--primary-500) 27.58%,
      var(--secondary-300) 51.58%,
      var(--tertiary-400) 93.45%
    );
  }

  &.opacity {
    opacity: 30%;
    @apply fixed top-0;
    height: 585px;

    &.absoluteEl {
      @apply absolute;
    }
  }
}

footer {
  background: linear-gradient(
    90deg,
    var(--primary-700) 1.53%,
    var(--secondary-700) 101.83%,
    var(--secondary-600) 101.84%
  );
  @apply p-2.5;
  @apply sm:px-8;
  z-index: 99;

  h6 {
    @apply xs:text-[15px] text-white;
    display: inline-block;
    @apply pr-6;
  }

  svg {
    @apply align-sub;
    @apply mr-3;
  }
}

table.table {
  width: 100%;
  font-size: 14px;

  th,
  td {
    text-align: left;
    @apply p-2.5;
    //white-space: nowrap;
  }

  td {
    @apply py-4 align-top;
    a.link {
      @apply bg-background;
    }
  }

  thead {
    &.rounded {
      th:first-child {
        @apply rounded-l-md;
      }

      th:last-child {
        @apply rounded-r-md;
      }
    }

    th {
      @apply font-semibold;
    }

    &.white {
      @apply bg-white text-headings;
    }

    &.background {
      @apply bg-background text-headings;
    }
  }

  &.bordered {
    tbody > tr > td {
      border-bottom: 1px solid #efefef;
    }
  }

  &.middle-aligned {
    tbody > tr > td {
      vertical-align: middle;
    }
  }
}

.expand-column {
  padding-top: 0;
}

.expanded-table a.link {
  background-color: transparent !important;
}

.expanded-row-section {
  & > div {
    padding: 0;
    margin: 0;
  }
}

.expandable-comp {
  margin-top: 1rem;
  padding-top: 1rem;
  box-shadow: none !important;
  border-radius: 10px !important;

  & .company-selector {
    & > div {
      & > div {
        height: 40px;
      }
    }
  }
}

.card {
  border-radius: 10px;
  @apply bg-white;
}

.chip {
  @apply py-1.5 px-3 bg-surface-overlay border rounded-[16px] text-[14px] border-shadow inline-block text-headings
  cursor-pointer capitalize;
  letter-spacing: 0.25px;

  &.active {
    @apply bg-primary-500 border-primary-500 text-white;
  }

  &.disabled {
    @apply text-disabled cursor-not-allowed;
  }
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--body);
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--body);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-grey1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary-500;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-500;
  width: 8px;
}

:root {
  scrollbar-color: var(--primary-500) transparent !important;
  scrollbar-width: thin !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#sidebarContainer {
  overflow: auto;
  height: 100%;
}

#sidebarContainer::-webkit-scrollbar {
  width: 6px;
}

#sidebarContainer::-webkit-scrollbar-thumb {
  @apply bg-white;
}

#sidebarContainer::-webkit-scrollbar-track {
  margin-bottom: 26px;
}

.carousel-root {
  &.moreInsights {
    @apply rounded-[10px] bg-background;
    .carousel {
      .thumbs-wrapper.axis-vertical {
        @apply my-0 mx-1.5 rounded-b-[10px] visible;
        .thumb.selected,
        .thumb:hover {
          @apply border-grey2;
        }
      }
    }

    .imageCard {
      .content {
        @apply rounded-t-[10px] overflow-hidden;
        @apply m-2.5 rounded-[10px];
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

.query-btn {
  font-size: 14px;
  .icon {
    width: 19px;
    height: 19px;
  }
}

.blocker-desc {
  max-height: 50px;
  overflow-y: auto;
}

.vul-reference {
  max-height: 50px;
  overflow-y: auto;
}

.app-notifications {
  .notification-item:hover {
    background: #f6f9fc;
    border-radius: 6px;
    cursor: pointer;
  }
}

.fz-25-lh-34 {
  font-size: 25px;
  line-height: 34px;
}

.filter-btn {
  background-color: var(--primary-100) !important;
  color: var(--primary-800) !important;
  font-size: 15px;

  & > svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.PrivateSwipeArea-root {
  display: none;
}

.hide-filter {
  display: none;
}

.filter-component {
  background-color: var(--primary-50);
  border-radius: 10px;
  padding: 15px 20px;

  .filter-options {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    flex-wrap: wrap;

    .box-indicator {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid var(--primary-50);
      position: absolute;
      top: -29px;
      right: 35px;
    }

    .filter {
      flex-basis: auto;
      min-height: 121px;
      border-right: 1px solid var(--grey2);
      padding-right: 25px;
      margin-right: 35px;
      flex-wrap: wrap;

      &.last-filter {
        border: none;
        margin-right: 0;
        padding: 0;
      }

      .name {
        color: var(--grey3);
        font-weight: 600;
        text-transform: capitalize;
      }
      .option {
        ul {
          li {
            display: inline-block;
            padding: 5px 12px;
            color: var(--headings);
            background: var(--surface-overlay);
            margin-right: 15px;
            margin-bottom: 15px;
            border-radius: 30px;
            border: 1px solid var(--border);
            text-transform: capitalize;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: var(--white);
              background: var(--functional-blue-600);
            }

            &.active {
              color: var(--white);
              background: var(--functional-blue-600);
            }
          }
        }
      }
      .date {
        display: flex;

        .from-date,
        .to-date {
          padding: 0;
          width: 180px;
          & > div {
            margin-top: 0;
            input {
              height: 35px !important;
              padding: 0 8px;
            }
          }
        }

        & > div {
          flex: auto;
          padding: 5px 10px 0;
        }
        .range {
          display: flex;

          .from-range,
          .to-range {
            padding: 0;
            width: 180px;
            & > div {
              margin-top: 0;
              input {
                height: 35px !important;
                padding: 0 8px;
              }
            }
          }
        }

        & > div {
          flex: auto;
          padding: 5px 10px 0;
        }
      }
    }
  }

  .dropdown {
    display: flex;

    .init-dropdown {
      padding: 0;
      height: 54px;
      width: 370px;

      & > div {
        margin-top: 0;

        input {
          height: 35px !important;
          padding: 0 8px;
        }
      }
    }

    & > div {
      flex: auto;
      padding: 5px 10px 0;
    }
  }

  .root-text {
    background: var(--primary-100);
    border-radius: 14px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    float: left;
    clear: left;
    min-width: 50px;
    padding: 4px 8px 4px 8px;
    margin: 0px 4px;
  }

  .row {
    display: flex;
  }

  .filter-actions {
    text-align: right;
    padding-top: 15px;
    border-top: 1px solid var(--grey2);
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .react-datepicker-popper {
    z-index: 100;
    margin-left: -10px;
    margin-top: 10px;

    .react-datepicker__navigation {
      margin-top: 7px;
    }
  }
}

.bloker-status-item:hover {
  background: #f6f9fc;
  border-radius: 6px;
  cursor: pointer;
}

.disclr-sec {
  background-color: #f6f9fc;
  color: #5f6162;
}

.disclr-sec-prim {
  background-color: var(--primary-50);
  color: #5f6162;
}

.chat-sub-title {
  font-size: 0.95rem;
}

.disclr-sec-def-pos {
  top: 140px;
}

.disclr-sec-second-pos {
  top: 25px;
}

.chatarea {
  top: 130px;
}

.desc-list-marker {
  &::marker {
    color: var(--secondary-700);
    font-size: 22px;
  }
}

.user-details-side {
  padding-left: 10px;
  height: calc(100vh - 72px);
  position: relative;

  .user-details {
    display: flex;

    .company-name {
      text-transform: capitalize;
    }

    .profile-pic {
      flex-basis: 84px;
      height: 84px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      background-color: var(--primary-500);

      & > div {
        font-size: 34px;
        color: var(--white);
        font-weight: 700;
      }
    }

    .details {
      flex: 1;
    }
  }

  .role-details {
    margin-top: 40px;

    .current-role-info {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .current-role {
      & > p {
        color: var(--subtitle);
        font-size: 20px;
        margin-bottom: 15px;
      }
      & > .role {
        & > ul {
          & > li {
            display: inline-block;
            padding: 5px 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 16px;
            color: var(--subtitle);
            background-color: var(--border);
            border: 1px solid var(--border);

            &.current {
              color: var(--white);
              background-color: var(--primary-500);
            }
          }
        }
      }
    }
  }

  .invite-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    & > .cancel-invite {
      margin-right: 15px;
    }
  }
}

.user-list {
  .user-div {
    text-transform: capitalize;
  }
}

.filter-options {
  .indiv-dropdown {
    z-index: 0;
  }
}

.add-users {
  height: 70vh;

  & > .add-tab {
    min-height: 60vh;
    overflow-y: auto;
    & > .customer-form {
      height: 60vh;
    }
    & > .siemba-form {
      height: 60vh;
    }
  }
}

.invite-users {
  height: 70vh;
  & > .invite-form {
    min-height: 60vh;
  }
}

//annotation for reports
.annotationLayer {
  & > .linkAnnotation > a:hover {
    background: none !important;
    box-shadow: none;
    opacity: 0 !important;
  }
}

.search-select-items {
  padding: 14px !important;

  &.selected {
    background-color: var(--primary-50);
  }

  & > div {
    width: 100%;

    & > p {
      width: inherit;

      & > span {
        white-space: break-spaces;
      }
    }
  }
}

.update-asset-stage {
  & ul.sub-stage {
    display: flex;
    position: relative;
    margin-top: 20px;

    & > hr {
      border: none;
      width: 190px;
      background: var(--primary-500);
      position: absolute;
      left: 30px;
      top: 18px;
      height: 2px;
      z-index: 0;

      &.second-divider {
        left: 230px;
      }
      &.disabled {
        background: var(--grey1) !important;
      }
    }

    & > li {
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 120px;
      z-index: 1;

      & > .level-name {
        font-size: 12px;
        margin-top: 5px;
        font-weight: 600;

        &.disabled {
          color: var(--subtitle);
        }
      }

      & > .level-no {
        width: 36px;
        display: flex;
        height: 36px;
        background: var(--primary-500);
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: 600;

        &.disabled {
          background: var(--grey1) !important;
        }
      }
    }
  }
}

.download-link {
  color: inherit;
  text-decoration: none;
}

.download-link:hover .download-import-template {
  color: gray;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.25px;
}
.download-import-template {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.25px;
}

.download-text:hover {
  color: gray;
}

.background-shade-import-assets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 17vh;

  .upload-button {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.25px;
  }
}
.update-auto-interation {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}

.note {
  background: var(--background);
  padding: 25px 16px;
  font-weight: 600;
  border-radius: 8px;
}

.current-start-date {
  padding: 10px 0 10px;

  & > span {
    font-weight: bold;
  }
}

.pentest-iteration-history {
  margin-top: 20px;

  & > .history-toggle {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: var(--body);

    & > svg {
      display: inline-block;
      margin-left: 5px;
      margin-top: 4px;
      cursor: pointer;

      &.up {
        transform: rotate(180deg);
      }
    }

    & + div {
      padding: 0;
      margin: 0;
      max-height: 260px;
      border-radius: 0;

      & .iteration-history-table {
        padding: 0;
        margin: 0;
        height: 240px;
      }
    }
  }
}

.justification-error {
  text-align: left;
  font-size: 12px;
  margin: 3px 10px 0px;
  color: #f65c5c;
}

.expandable-table-header {
  top: -16px !important;
}

#count-stroke{
  color: var(--primary-500);
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke: 1px var(--primary-500);
}

.gpt-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.gpt-loader::before , .gpt-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid var(--functional-blue);
  animation: prixClipFix 2s linear infinite ;
}
.gpt-loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: var(--functional-orange);
}
@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}

.progress{
  transition: width 0.3s ease;
}

.th-align-center {
  & > div {
    /* Apply styles to the child <div> */
    @apply justify-center;
  }
}